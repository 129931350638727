document.addEventListener('DOMContentLoaded', () => {
  if (typeof wheelId !== 'undefined') {
    // eslint-disable-next-line no-undef
    const globalWheelId = wheelId;

    const { localStorage } = window;
    const storageKey = `wheelEdit${globalWheelId}`;

    if (typeof wheelAdmin !== 'undefined') {
      // add admin key to local storage
      // eslint-disable-next-line no-undef
      localStorage.setItem(storageKey, JSON.stringify(wheelAdmin));
    }

    const wheelData = JSON.parse(localStorage.getItem(storageKey));
    if (wheelData) {
      const editButton = document.querySelector('.js-wheel-edit-button');

      if (editButton && typeof wheelData === 'object') {
        // update edit button URL
        if (typeof wheelData.adminKey === 'string') {
          const newButtonUrl = editButton.getAttribute('href')
            .replace('..adminKey..', wheelData.adminKey);

          editButton.setAttribute('href', newButtonUrl);
        }

        // show edit button
        editButton.style.display = null;
      }
    }
  }
});
