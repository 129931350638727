document.addEventListener('DOMContentLoaded', () => {
  function htmlToElement(html) {
    const template = document.createElement('template');
    template.innerHTML = html.trim();

    return template.content.firstChild;
  }

  document.addEventListener('click', (event) => {
    const { target } = event;

    const addButton = target.closest('.form-collection__add');
    if (addButton) {
      // add new field
      const widget = addButton.closest('.form-widget');
      const choices = widget.querySelector('div');

      const max = choices.getAttribute('data-max');

      if (!max || max > choices.children.length) {
        const prototype = choices.getAttribute('data-prototype');

        let newChoiceName = 0;
        if (choices.children.length) {
          const lastChild = choices.children.item(choices.children.length - 1);
          const childWidget = lastChild.querySelector('[data-name]');

          newChoiceName = parseInt(childWidget.getAttribute('data-name'), 10) + 1;
        }

        const newChoiceHtml = prototype.replaceAll('__name__', `${newChoiceName}`);
        const newChoiceElement = htmlToElement(newChoiceHtml);

        choices.appendChild(newChoiceElement);
      }
    }

    const removeButton = target.closest('.form-collection__remove');
    if (removeButton) {
      // remove field
      const row = removeButton.closest('.form-widget').parentElement;
      const choices = row.parentElement;

      const min = choices.getAttribute('data-min');

      if (!min || min < choices.children.length) {
        row.remove();
      }
    }
  });
});
