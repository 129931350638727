const confetti = require('canvas-confetti/src/confetti');

document.addEventListener('DOMContentLoaded', () => {
  const trollValue = '';
  const spinTriggers = document.querySelectorAll('.js-wheel-spin-trigger');

  if (spinTriggers.length && typeof globalWheelChoices !== 'undefined') {
    let spinInProgress = false;

    const wheel = document.querySelector('.wheel-container');
    const resultContainer = document.querySelector('.wheel-result-container');
    const resultContainerText = resultContainer.querySelector('.wheel-result-text');
    const resultContainerUrl = resultContainer.querySelector('.wheel-result-url');
    const resultCopyButton = resultContainer.querySelector('.js-wheel-result-copy');

    resultCopyButton.addEventListener('click', () => {
      resultContainerUrl.select();
      resultContainerUrl.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(resultContainerUrl.value);

      resultCopyButton.innerHTML = 'Copied!';

      setTimeout(() => {
        resultCopyButton.innerHTML = 'Copy link';
      }, 1500);
    });

    const spinButton = document.querySelector('button.js-wheel-spin-trigger');

    spinTriggers.forEach((spinTrigger) => {
      spinTrigger.addEventListener('click', (event) => {
        if (spinInProgress) {
          return;
        }

        spinInProgress = true;

        resultContainer.style.display = 'none';

        const { target } = event;
        const spinUrl = target.getAttribute('data-spin-url');

        if (spinUrl) {
          if (spinButton) {
            spinButton.setAttribute('disabled', null);
          }

          let spinStartTimeout = 0;

          // eslint-disable-next-line no-undef
          if (trollValue && globalWheelChoices.findIndex(element => element === trollValue) >= 0) {
            // eslint-disable-next-line no-undef
            spinStartTimeout = (globalWheelChoices.length * 150) + 500;

            const wheelTextElements = document.querySelectorAll('.wheel-container textPath');

            wheelTextElements.forEach((textElement, textElementKey) => {
              setTimeout((element) => {
                // eslint-disable-next-line no-param-reassign
                element.innerHTML = trollValue;
              }, textElementKey * 150, textElement);
            });
          }

          setTimeout(() => {
            wheel.style.transform = null;
            if (wheel.classList.contains('rotate-end')) {
              wheel.classList.replace('rotate-end', 'rotate-full');
            } else {
              wheel.classList.add('rotate-full');
            }

            const intervalId = setInterval(() => {
              // stop rotation?
              if (wheel.hasAttribute('data-end-rotation')) {
                wheel.classList.replace('rotate-full', 'rotate-end');

                clearInterval(intervalId);

                const timeout = parseFloat(wheel.getAttribute('data-wheel-animation-duration')) * 1000;
                setTimeout(() => {
                  resultContainer.style.display = null;

                  const audio = new Audio('/audio/party-horn.ogg');
                  audio.play();

                  const confettiEnd = Date.now() + (3 * 1000);

                  (function frame() {
                    confetti({
                      particleCount: 5,
                      angle: 60,
                      spread: 55,
                      origin: { x: 0, y: 0.6 },
                    });
                    confetti({
                      particleCount: 5,
                      angle: 120,
                      spread: 55,
                      origin: { x: 1, y: 0.6 },
                    });

                    if (Date.now() < confettiEnd) {
                      requestAnimationFrame(frame);
                    }
                  }());
                }, timeout + 200);
              }
            }, 500);

            const request = new XMLHttpRequest();
            request.open('GET', spinUrl);
            request.onload = (progressEvent) => {
              const requestTarget = progressEvent.target;

              if (requestTarget.status === 200) {
                const data = JSON.parse(requestTarget.response);
                const spinResultUrl = data.spinUrl;
                const spinResult = data.spin.result;

                // eslint-disable-next-line no-undef
                const wheelChoices = globalWheelChoices;

                const resultIndex = wheelChoices.findIndex(element => element === spinResult);
                const wheelRatio = resultIndex / wheelChoices.length;
                const wheelAngle = 360 - (360 * wheelRatio);
                const wheelPercentage = 100 * wheelRatio;
                let wheelAnimationDuration = 2 - (2 * wheelRatio);

                if (wheelAnimationDuration === 0.0) {
                  wheelAnimationDuration = 2;
                }

                document.documentElement.style.setProperty('--wheel-animation-end-angle', `${wheelAngle}deg`);
                document.documentElement.style.setProperty('--wheel-animation-end-percentage', `${wheelPercentage}%`);
                document.documentElement.style.setProperty('--wheel-animation-end-duration', `${wheelAnimationDuration}s`);
                wheel.setAttribute('data-end-rotation', 'yes');
                wheel.setAttribute('data-wheel-animation-duration', `${wheelAnimationDuration}`);

                if (trollValue && wheelChoices.findIndex(element => element === trollValue) >= 0) {
                  resultContainerText.innerHTML = trollValue;

                  setTimeout(() => {
                    const wheelTextElements = document.querySelectorAll('.wheel-container textPath');

                    wheelTextElements.forEach((textElement, textElementKey) => {
                      // eslint-disable-next-line no-param-reassign
                      textElement.innerHTML = wheelChoices[textElementKey];
                    });

                    resultContainerText.innerHTML = spinResult;
                  }, (wheelAnimationDuration * 1000) + 2000);
                } else {
                  resultContainerText.innerHTML = spinResult;
                }

                resultContainerUrl.value = spinResultUrl;

                spinInProgress = false;
                if (spinButton) {
                  spinButton.removeAttribute('disabled');
                  spinButton.innerHTML = 'Spin again!';
                }
              } else {
                // eslint-disable-next-line no-console
                console.log('There was a problem with the request.');
              }

              spinInProgress = false;
            };
            request.send();
          }, spinStartTimeout);
        }
      });
    });
  }
});
